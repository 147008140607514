import * as React from 'react';
import { Select } from '@blueprintjs/select';
import { FormGroup, Button, MenuItem } from '@blueprintjs/core';
import { useEffect } from 'react';
import { Category } from '../models/category.model';
import { axios } from '../session';
import { WithTranslation, withTranslation } from 'react-i18next';

const BlueprintSelect = Select.ofType<Category>();

export interface Props extends WithTranslation {
  inline?: boolean;
  label?: string;
  category: Category;
  parent?: Category;
  ignoreParent?: boolean;
  onChange: (category: Category) => void;
}

export function CategorySelect({
  onChange,
  category,
  label,
  parent,
  inline = false,
  i18n,
  ignoreParent
}: Props) {
  const [categories, setCategories] = React.useState([] as Category[]);

  useEffect(() => {
    const controller = new AbortController();
    const fetch = async () => {
      let params = '';
      // (window as any).kokot = parent;
      // console.log("figure out for parent", parent);
      // const params = parent && parent.exists ? `category_id=${parent.id}` : 'category_id=top';

      if (parent && parent.exists) {
        params = `category_id=${parent.id}`
      }

      if ((!parent || !parent.exists ) && !ignoreParent) {
        params = 'category_id=top'
      }

      // if (parent && parent.parent && parent.parent.exists) {
      //   console.log('p1', category);
      //   params = `category_id=${parent.id}`
      // } else if (parent && !parent.parent?.exists) {
      //   console.log('p2', category);
      //   params = `category_id=${parent.id}`
      // } else {
      //   console.log('p3', category);
      //   params = 'category_id=top'
      // }

      // console.log('category select =>', params, category, parent);

      const response = await axios.get(`/categories?${params}`, {
        signal: controller.signal,
      });

      if (typeof response === 'undefined') {
        return
      }

      // console.log('data', data);
      const mapped = response.data.categories.map((c: unknown) => {
        // console.log('deserializing c', c);
        return new Category(c);
      })
      // console.log('mapped', mapped);
      mapped.splice(0, 0, new Category({ name: i18n.t('blank_category'), parent: parent }))
      // console.log('mapped spliced', mapped);
      setCategories(mapped);
    };

    fetch();
    return () => {
      controller.abort();
    };
  }, [ parent, category, i18n, ignoreParent ]);

  console.log('categories', categories);
  return (
    <FormGroup inline={inline} label={label} className='m-b-0'>
      <BlueprintSelect
        filterable={false}
        items={categories}
        activeItem={category}
        itemRenderer={(item, options) => {
          console.log('rendering item', item);
          return <MenuItem
            key={item.id || 'none'}
            text={item.name}
            label={item.parent?.name}
            onClick={options.handleClick}
          />
        }}
        onItemSelect={(_category) => {
          onChange(_category);
        }}
      >
        <Button
          text={category.exists ? category.name : (parent && parent.exists ? 'Select Subcategory' : 'Select Category')}
          rightIcon="chevron-down"
          minimal
          className='m-b-0'
        />
      </BlueprintSelect>
    </FormGroup>
  );
}

export default withTranslation()(CategorySelect);
