import { Button, Classes, Dialog, Divider, InputGroup, MenuItem, Spinner, Tab, Tabs } from '@blueprintjs/core';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Form,
  FormField,
  FormFieldAlignment,
  FormFieldType,
} from '@patterns/form';
import { FlexRow } from '@patterns/ui';
import { convertFileToBase64, deepEqual } from '@patterns/core';
import { axios } from '../../session';
import { CustomField } from '../../models/custom_field.model';
import { IconRepository, ProductRepository } from '../../repository';
import { arrayMove } from '../../common';
import { Product } from '../../models/product.model';
import { IconImage } from '../settings/icons';
import IconPicker from './icon_picker';
import { Select } from '@blueprintjs/select';
import CategorySelect from '../category.select';
import { Category } from '../../models/category.model';

const ProductForm = Form.ofType<Product>();
const StringSelect = Select.ofType<string>();

export interface Props extends WithTranslation {
  fields: CustomField[];
  isOpen: boolean;
  onClose: (apply: boolean) => void;
  onClone: (product: Product) => void;
  product: Product;
}

export interface State {
  isUploading: boolean;
  isSaving: boolean;
  globalIcons: IconImage[];
  globalSelection: string[];
  showIconPool: boolean;
  product: Product;
  extras: any;
  tab: string;
}

export class ProductDialog extends React.Component<Props, State> {
  fileInput = React.createRef<HTMLInputElement>();

  iconFileInput = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      globalIcons: [],
      globalSelection: [],
      showIconPool: false,
      isUploading: false,
      product: props.product,
      extras: {},
      tab: 'basic'
    };
  }

  componentDidMount() {
    this.fetchIcons();
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(prevProps.product, this.props.product)) {
      if (!this.props.product.id) {
        this.setState({ product: new Product({}) })
      }
      if (this.props.product.id && this.props.product.id.length && this.props.product.id !== prevProps.product.id) {
        this.reload();
      }
    }
  }

  private fetchIcons = async () => {
    const { items } = await IconRepository.index(1, 1000, 'order', 'asc', {});
    const globalIcons = items.map(icon => ({
      src: `https://print.jit.fi/images/icons/${icon.fileName}`,
      width: 100,
      height: 100,
      alt: icon.fileName
    }));

    this.setState({ globalIcons })
  }

  private reload = async () => {
    const product = await ProductRepository.get(this.props.product.id);
    const extras = {} as any;
    this.props.fields.forEach((field) => {
      extras[field.id] = this.props.product.extras[field.id] || '';
    });

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      product,
      extras,
    });
  };

  private getFields = () => {
    const { t } = this.props;

    let category = new Category({});

    if (this.state.product.category) {
      category = this.state.product.category;
    }

    if (!this.state.product.category.parent) {
      // 1st level category
      let c = new Category();
      c.parent = new Category();
      c.parent.parent = category;
      category = c;
    } else if (this.state.product.category.parent && !this.state.product.category.parent.parent) {
      // 2nd category
      let c = new Category(); // 3rd level will be empty
      c.parent = this.state.product.category.parent; // 2nd level will child of cat.parent.parent
      category = c;
    } else {
      category = this.state.product.category;
    }

    // if (!category.parent) {
    //   category.parent = new Category();
    // }
    //
    // if (!category.parent.parent) {
    //   category.parent!.parent = new Category();
    // }

    console.log('rendering product with category', this.state.product, category);

    return [
      {
        id: 'nameLatin',
        label: t('products.name_latin'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'nameLatinSort',
        label: t('products.name_latin_sort'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'name1',
        label: t('products.name'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'name2',
        label: t('products.name2'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'internalCode',
        label: t('products.internal_code'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'barcode',
        label: t('products.barcode'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'color',
        label: t('products.color'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'color2',
        label: t('products.color2'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'category',
        label: t('settings.category'),
        type: FormFieldType.Custom,
        alignment: FormFieldAlignment.Left,
        visible: true,
        customElement: (product, onChange) => (
          <CategorySelect
            category={category.parent?.parent ?? new Category()}
            onChange={(c) => {
              const p = new Product(product);
              p.category = new Category();
              p.category.parent = new Category();
              p.category.parent.parent = c;
              console.log('setting top parent to', c, p.category.parent.parent)
              onChange(p);
            }}
          />
        ),
      },
      {
        id: 'subcategory',
        label: t('settings.subcategory'),
        type: FormFieldType.Custom,
        alignment: FormFieldAlignment.Left,
        visible: true,
        customElement: (product, onChange) => (
          <CategorySelect
            category={category.parent!}
            parent={category.parent!.parent!}
            onChange={(c) => {
              const p = new Product(product);
              p.category = new Category();
              p.category.parent = c;
              console.log('setting top parent to', c, p.category.parent)
              onChange(p);
            }}
          />
        ),
      },
      {
        id: 'thirdcategory',
        label: t('settings.thirdcategory'),
        type: FormFieldType.Custom,
        alignment: FormFieldAlignment.Left,
        visible: true,
        customElement: (product, onChange) => (
          <CategorySelect
            category={category}
            parent={category.parent}
            onChange={(c) => {
              const p = new Product(product);
              console.log('setting product category', c);
              p.category = c;
              onChange(p);
            }}
          />
        ),
      },
    ] as FormField<Product>[];
  };


  private getDescFields = () => {
    const { t } = this.props;
    return [
      {
        id: 'growZone',
        label: t('products.grow_zone'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'hardiness',
        label: t('products.hardiness'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'ground',
        label: t('products.ground'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'ground2',
        label: t('products.ground2'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'originCountry',
        label: t('products.origin_country'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'blossom',
        label: t('products.blossom'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'plantSize1',
        label: t('products.plant_size1'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'plantSize2',
        label: t('products.plant_size2'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'plantHeight',
        label: t('products.plant_height'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'plantWidth',
        label: t('products.plant_width'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'age',
        label: t('products.age'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
    ] as FormField<Product>[];
  };

  private getNotesFields = () => {
    const { t } = this.props;
    return [
      {
        id: 'price',
        label: t('products.price'),
        type: FormFieldType.Custom,
        alignment: FormFieldAlignment.Left,
        visible: true,
        customElement: (product, onChange) => <InputGroup
          value={product.price}
          onChange={evt => {
            product.price = evt.currentTarget.value.replace('.', ',');
            onChange(product)
          }}
        />
      },
      {
        id: 'quantity',
        label: t('products.quantity'),
        type: FormFieldType.Custom,
        alignment: FormFieldAlignment.Left,
        visible: true,
        customElement: (product, onChange) => <InputGroup
          value={product.quantity}
          onChange={evt => {
            product.quantity = evt.currentTarget.value.replace('.', ',');
            onChange(product)
          }}
        />
      },
      {
        id: 'packSize',
        label: t('products.pack_size'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'packWeight',
        label: t('products.pack_weight'),
        type: FormFieldType.Text,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'notes',
        label: t('products.notes'),
        type: FormFieldType.TextArea,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
      {
        id: 'notes2',
        label: t('products.notes2'),
        type: FormFieldType.TextArea,
        alignment: FormFieldAlignment.Left,
        visible: true,
      },
    ] as FormField<Product>[];
  };

  private getCustomFields = (column: number) => {
    return this.props.fields.filter((_, index) => index % 3 === column).map((field) => {
      if (field.type !== 'select') {
        return {
          id: field.id,
          label: field.label,
          type: FormFieldType.Text,
          alignment: FormFieldAlignment.Left,
          visible: false,
        };
      }

      const label = typeof this.state.product.extras[field.id] === 'undefined' ? '' : this.state.product.extras[field.id];

      return {
        id: field.id,
        label: field.label,
        type: FormFieldType.Custom,
        alignment: FormFieldAlignment.Left,
        visible: false,
        customElement: () => {
          return <StringSelect
            items={field.options}
            activeItem={label}
            itemRenderer={(item, options) => <MenuItem
              key={`custom-option-${item.replace(' ', '')}`}
              text={item}
              onClick={options.handleClick}
            />}
            onItemSelect={item => {
              const product = new Product(this.state.product);
              product.extras[field.id] = item;
              this.setState({ product })
            }}>
              <Button
                text={label}
                rightIcon="chevron-down"
              />
            </StringSelect>
        }
      } as FormField<CustomField>;
    });
  };

  private onChange = (product: Product) => {
    console.log('form product changed', product);
    this.setState({ product });
  };

  private onExtraChange = (extras: any) => {
    const { product } = this.state;
    product.extras = extras;
    this.setState({ extras });
  };

  private close = () => this.props.onClose(false);

  private handleFileSelect = (fileList: FileList | null) => {
    if (!fileList) {
      return;
    }

    // const file = fileList[0];
    this.setState({ isUploading: true }, async () => {
      const file = fileList[0];
      const data = await convertFileToBase64(file);
      await axios.post(`/products/${this.props.product.id!}/upload`, {
        data,
        type: file.type,
      });
      this.setState({ isUploading: false }, () => this.reload());
    });
  };

  private handleIconFileSelect = (fileList: FileList | null) => {
    if (!fileList) {
      return;
    }

    // const file = fileList[0];
    this.setState({ isUploading: true }, async () => {
      const file = fileList[0];
      const data = await convertFileToBase64(file);
      await axios.post(`/products/${this.state.product.id}/upload_icon`, {
        data,
        type: file.type,
      });
      this.setState({ isUploading: false }, () => this.reload());
    });
  };

  private addImage = () => {
    this.fileInput.current?.click();
  };

  private addIcon = () => {
    this.iconFileInput.current?.click();
  };

  private save = async () => {
    await ProductRepository.save(this.state.product);
    this.props.onClose(true);
  };

  private saveIconOrdering = async () => {
    const ordering = this.state.product.icons.map((i) => i.id);
    await axios.post(`/products/${this.state.product.id}/reorder_icons`, {
      ordering,
    });
  };

  private saveImageOrdering = async () => {
    const ordering = this.state.product.images.map((i) => i.id);
    await axios.post(`/products/${this.state.product.id}/reorder_images`, {
      ordering,
    });
  };

  private moveImageLeft = async (index: number) => {
    const images = [...this.state.product.images];
    arrayMove(images, index, index - 1);
    images.forEach((i, idx) => {
      i.order = idx;
    });
    const { product } = this.state;
    product.images = images;
    this.setState({ product }, () => this.saveImageOrdering());
  };

  private moveImageRight = (index: number) => {
    const images = [...this.state.product.images];
    arrayMove(images, index, index + 1);
    images.forEach((i, idx) => {
      i.order = idx;
    });
    const { product } = this.state;
    product.images = images;
    this.setState({ product }, () => this.saveImageOrdering());
  };

  private movePoolIconLeft = (index: number) => {
    const icons = [...this.state.product.globalIcons];
    arrayMove(icons, index, index - 1);
    const { product } = this.state;
    product.globalIcons = icons;
    this.setState({ product }, () => this.savePoolIconOrdering());
  };

  private movePoolIconRight = (index: number) => {
    const icons = [...this.state.product.globalIcons];
    arrayMove(icons, index, index + 1);
    const { product } = this.state;
    product.globalIcons = icons;
    this.setState({ product }, () => this.savePoolIconOrdering());
  };

  private trashPoolIcon = (index: number) => {
    this.setState({ isUploading: true }, async () => {
      const { product } = this.state;
      const icons = [...product.globalIcons];
      icons.splice(index, 1);
      product.globalIcons = icons;
      await this.savePoolIconOrdering();
      this.setState({ product, isUploading: false });
    });
  };

  private savePoolIconOrdering = async () => {
    await axios.post(`/products/${this.state.product.id}/global_icons`, {
      selection: this.state.product.globalIcons
    });
  }

  private moveIconLeft = (index: number) => {
    const icons = [...this.state.product.icons];
    arrayMove(icons, index, index - 1);
    icons.forEach((i, idx) => {
      i.order = idx;
    });
    const { product } = this.state;
    product.icons = icons;
    this.setState({ product }, () => this.saveIconOrdering());
  };

  private moveIconRight = (index: number) => {
    const icons = [...this.state.product.icons];
    arrayMove(icons, index, index + 1);
    icons.forEach((i, idx) => {
      i.order = idx;
    });
    const { product } = this.state;
    product.icons = icons;
    this.setState({ product }, () => this.saveIconOrdering());
  };

  private trashIcon = (index: number) => {
    this.setState({ isUploading: true }, async () => {
      const { product } = this.state;
      const icons = [...product.icons];
      const icon = icons[index];
      icons.splice(index, 1);
      icons.forEach((i, idx) => {
        i.order = idx;
      });
      product.icons = icons;
      await axios.delete(`/products/${this.state.product.id}/icons/${icon.id}`);
      this.setState({ product, isUploading: false });
    });
  };

  private trashImage = (index: number) => {
    this.setState({ isUploading: true }, async () => {
      const { product } = this.state;
      const images = [...product.images];
      const icon = images[index];
      images.splice(index, 1);
      images.forEach((i, idx) => {
        i.order = idx;
      });
      product.images = images;
      await axios.delete(
        `/products/${this.state.product.id}/images/${icon.id}`
      );
      this.setState({ product, isUploading: false });
    });
  };

  private onIconPoolClose = () => {
    this.setState({ showIconPool: false })
  }

  private onIconPoolSelect = async (selection: string[]) => {
    const product = this.state.product;
    selection.forEach(fileName => {
      if (!product.globalIcons.includes(fileName)) {
        product.globalIcons.push(fileName)
      }
    });
    await axios.post(`/products/${this.state.product.id}/global_icons`, {
      selection: product.globalIcons
    });

    this.setState({ showIconPool: false, product });
  }

  private selectIcons = () => {
    this.setState({ showIconPool: true })
  }

  private clone = () => {
    this.setState({ isUploading: true }, async () => {
      const { data } = await axios.get(
        `/products/${this.state.product.id}/clone`
      );
      this.setState({ isUploading: false }, () => {
        const newProduct = new Product(data);
        this.props.onClone(newProduct);
      });
    });
  };

  private renderImages = () => {
    return this.state.product.images.sort((a, b) => a.order - b.order).map((image, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className="image-box" key={`image-${index}`}>
          <div className="img-container">
            <img
              alt="product"
              src={`https://print.jit.fi/images/${this.state.product.organization_id}/${this.state.product.id}/${image.fileName}`}
            />
          </div>
          <FlexRow className="actions">
            <Button
              disabled={index === 0}
              minimal
              icon="chevron-left"
              onClick={() => this.moveImageLeft(index)}
            />
            <Button
              minimal
              icon="trash"
              onClick={() => this.trashImage(index)}
            />
            <Button
              disabled={index === this.state.product.images.length - 1}
              minimal
              icon="chevron-right"
              onClick={() => this.moveImageRight(index)}
            />
          </FlexRow>
        </div>
      );
    });
  };

  private renderGlobalIcons = () => {
    return this.state.product.globalIcons.map((image, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className="image-box" key={`image-${index}`}>
          <div className="img-container">
            <img
              alt="product"
              src={`https://print.jit.fi/images/icons/${image}`}
            />
          </div>
          <FlexRow className="actions">
            <Button
              disabled={index === 0}
              minimal
              icon="chevron-left"
              onClick={() => this.movePoolIconLeft(index)}
            />
            <Button
              minimal
              icon="trash"
              onClick={() => this.trashPoolIcon(index)}
            />
            <Button
              disabled={index === this.state.product.globalIcons.length - 1}
              minimal
              icon="chevron-right"
              onClick={() => this.movePoolIconRight(index)}
            />
          </FlexRow>
        </div>
      );
    });
  };

  private renderIcons = () => {
    return this.state.product.icons.sort((a, b) => a.order - b.order).map((image, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className="image-box" key={`image-${index}`}>
          <div className="img-container">
            <img
              alt="product"
              src={`https://print.jit.fi/images/${this.state.product.organization_id}/${this.state.product.id}/${image.fileName}`}
            />
          </div>
          <FlexRow className="actions">
            <Button
              disabled={index === 0}
              minimal
              icon="chevron-left"
              onClick={() => this.moveIconLeft(index)}
            />
            <Button
              minimal
              icon="trash"
              onClick={() => this.trashIcon(index)}
            />
            <Button
              disabled={index === this.state.product.images.length - 1}
              minimal
              icon="chevron-right"
              onClick={() => this.moveIconRight(index)}
            />
          </FlexRow>
        </div>
      );
    });
  };

  private onTabChange = (tab: string) => this.setState({ tab })

  public render() {
    const { t } = this.props;
    return (
      <Dialog
        isOpen={this.props.isOpen}
        title={t('products.edit_product')}
        className="product-dialog bp3-dark"
        onClose={() => this.props.onClose(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <Tabs
            className="custom-tabs m-0 p-0"
            selectedTabId={this.state.tab}
            onChange={this.onTabChange}>
            <Tab
              id="basic"
              title={t('products.basic')}
              className='p-t-24'
              panel={<FlexRow>
                <div className="f-1">
                  <ProductForm
                    fields={this.getFields()}
                    item={this.state.product}
                    onChange={this.onChange}
                    inline
                  />
                </div>
                <div className="f-1">
                  <ProductForm
                    fields={this.getDescFields()}
                    item={this.state.product}
                    onChange={this.onChange}
                    inline
                  />
                </div>
                <div className="f-1">
                  <ProductForm
                    fields={this.getNotesFields()}
                    item={this.state.product}
                    onChange={this.onChange}
                    inline
                  />
                </div>
              </FlexRow>}
            />
            <Tab
              id="custom"
              title={t('custom_fields.title')}
              className='p-t-24'
              panel={
                <FlexRow>
                  <div className="f-1">
                    <ProductForm
                      fields={this.getCustomFields(0) as any}
                      item={this.state.extras}
                      onChange={this.onExtraChange}
                      inline
                    />
                  </div>
                  <div className="f-1">
                    <ProductForm
                      fields={this.getCustomFields(1) as any}
                      item={this.state.extras}
                      onChange={this.onExtraChange}
                      inline
                    />
                  </div>
                  <div className="f-1">
                    <ProductForm
                      fields={this.getCustomFields(2) as any}
                      item={this.state.extras}
                      onChange={this.onExtraChange}
                      inline
                    />
                  </div>
                </FlexRow>
              }
            />
            <Tab
              id="images"
              title={t('products.images')}
              className='p-t-24 p-l-36'
              panel={<>
                <FlexRow>
                  <h2>{t('products.images')}</h2>
                  <Button
                    minimal
                    intent="primary"
                    text={t('products.add_image')}
                    icon="plus"
                    className="m-l-24"
                    onClick={this.addImage}
                  />
                </FlexRow>
                <FlexRow>{this.renderImages()}</FlexRow>
                <Divider />
                <FlexRow>
                  <h2>{t('products.global_icons')}</h2>
                  <Button
                    minimal
                    intent="primary"
                    text={t('products.select_icons')}
                    icon="plus"
                    className="m-l-24"
                    onClick={this.selectIcons}
                  />
                </FlexRow>
                <FlexRow>{this.renderGlobalIcons()}</FlexRow>

                <Divider className='m-t-24' />
                <FlexRow>
                  <h2>{t('products.custom_icons')}</h2>
                  <Button
                    minimal
                    intent="primary"
                    text={t('products.add_icon')}
                    icon="plus"
                    className="m-l-24"
                    onClick={this.addIcon}
                  />
                </FlexRow>
                <FlexRow>{this.renderIcons()}</FlexRow>

                <FlexRow>{this.state.isUploading && <Spinner size={64} />}</FlexRow>
              </>}
            />
          </Tabs>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <FlexRow>
              <Button
                text={t('products.clone')}
                intent="primary"
                icon="add"
                onClick={this.clone}
                disabled={this.state.isSaving}
                loading={this.state.isSaving}
                style={{
                  marginLeft: 0,
                }}
              />
            </FlexRow>
            <FlexRow className="jc-e">
              <Button
                text={t('close')}
                intent="none"
                icon="cross"
                onClick={this.close}
                disabled={this.state.isSaving}
                loading={this.state.isSaving}
              />
              <Button
                text={t('save')}
                intent="success"
                icon="saved"
                className="m-l-12"
                onClick={this.save}
                disabled={this.state.isSaving}
                loading={this.state.isSaving}
              />
            </FlexRow>
          </div>
        </div>

        <input
          accept="image/png, image/jpeg"
          type="file"
          ref={this.fileInput}
          onChange={(e) => this.handleFileSelect(e.target.files)}
          style={{ display: 'none' }}
        />

        <input
          accept="image/png, image/jpeg"
          type="file"
          ref={this.iconFileInput}
          onChange={(e) => this.handleIconFileSelect(e.target.files)}
          style={{ display: 'none' }}
        />

        <IconPicker
          isOpen={this.state.showIconPool}
          onClose={this.onIconPoolClose}
          onSelect={this.onIconPoolSelect}
        />
      </Dialog>
    );
  }
}

export default withTranslation()(ProductDialog);
