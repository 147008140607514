/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default */
import * as React from 'react';
/* eslint-disable @typescript-eslint/naming-convention */
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Alignment,
  Column,
  ColumnType,
  DataTable,
  FilterStates,
} from '@patterns/datatable';
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Icon,
  InputGroup,
  Spinner,
  Tab,
  Tabs,
} from '@blueprintjs/core';
import { FlexRow, Toolbar } from '@patterns/ui';
import { DummyTemplate, Template } from '../models/template.model';
import ProductDialog from '../components/products/product.dialog';
import { CustomField } from '../models/custom_field.model';
import { MainLayout } from '../layouts/main.layout';
import { Breadcrumbs } from '../breadcrumbs';
import { Product } from '../models/product.model';
import {
  CustomFieldRepository,
  MasterProductRepository,
  ProductRepository,
} from '../repository';
import { axios, priceFormat } from '../common';
import CategorySelect from '../components/category.select';
import { Category } from '../models/category.model';

const ProductsTable = DataTable.ofType<Product>();

type PossibleTab = 'master-products' | 'own-products';

type QtyMap = { [id: string]: number };

interface Props extends WithTranslation, RouteComponentProps {}

interface State {
  category: Category,
  customFields: CustomField[];
  isLoading: boolean;
  masterQuery: string;
  ownSelection: Product[];
  selected: Product;
  showDialog: boolean;
  subcategory: Category;
  thirdcategory: Category;
  tab: PossibleTab;
  template: Template;
  qtys: QtyMap;
  query: string;
}

export class Products extends React.Component<Props, State> {
  private cancelToken: any;

  private ownTable = React.createRef<DataTable<Product>>();

  private masterTable = React.createRef<DataTable<Product>>();

  constructor(props: Props) {
    super(props);
    this.state = {
      category: new Category(),
      customFields: [],
      isLoading: false,
      masterQuery: '',
      ownSelection: [],
      selected: new Product({}),
      showDialog: false,
      subcategory: new Category({}),
      thirdcategory: new Category({}),
      tab: 'own-products',
      template: DummyTemplate,
      qtys: {} as QtyMap,
      query: '',
      // showDialog: true,
    } as State;
  }

  componentDidMount() {
    void this.fetchCustomFields();
  }

  private onTabChange = (tab: PossibleTab) => this.setState({ tab });

  private onSelect = async () => {};

  private onEdit = async (selected: Product) => {
    this.setState({
      selected,
      showDialog: true,
    });
  };

  private onImageError = (error: any) => {
    error.target.style = 'display: none;';
  }

  private getImage = (product: Product, master: boolean) => {
    const url = master
      ? `https://print.jit.fi/images/${product.remoteId}-0.jpg`
      : product.images.length > 0
      ? `https://print.jit.fi/images/${product.organization_id}/${product.id}/${product.images[0].fileName}`
      : '';

    return  <img
      className="table-cell-image"
      src={url}
      onError={this.onImageError}
      alt=""
    />
  }

  private getColumns(master = false): Column<Product>[] {
    const { t } = this.props;
    const columns = [
      {
        id: 'nameLatin',
        title: t('products.name_latin'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 3,
        visible: true,
        format: (product) => <span>{product.nameLatin}</span>,
      },
      {
        id: 'nameLatinSort',
        title: t('products.name_latin_sort'),
        type: ColumnType.Text,
        sortable: true,
        filterable: true,
        alignment: Alignment.Left,
        flex: 3,
        visible: true,
        format: (product) => <span>{product.nameLatinSort}</span>,
      },
      {
        id: 'image',
        title: t('products.image'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        width: 82,
        visible: true,
        format: (product) => this.getImage(product, master)
      },
      {
        id: 'category',
        title: t('settings.category'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
        format: (product) => {
          return product.category.parent?.parent?.name
        }
      },
      {
        id: 'subcategory',
        title: t('settings.subcategory'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
        format: (product) => {
          return product.category.parent?.name
        }
      },
      {
        id: 'thirdcategory',
        title: t('settings.thirdcategory'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
        format: (product) => {
          return product.category.name
        }
      },
      {
        id: 'internalCode',
        title: t('products.internal_code'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'barcode',
        title: t('products.barcode'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
      },
      {
        id: 'name1',
        title: t('products.name1'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
      },
      {
        id: 'name2',
        title: t('products.name2'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
      },
      {
        id: 'color',
        title: t('products.color'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
      },
      {
        id: 'growZone',
        title: t('products.grow_zone'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'color2',
        title: t('products.color2'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
      },
      {
        id: 'growZone',
        title: t('products.grow_zone'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'hardiness',
        title: t('products.hardiness'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'ground',
        title: t('products.ground'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'ground2',
        title: t('products.ground2'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'originCountry',
        title: t('products.origin_country'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'blossom',
        title: t('products.blossom'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'plantSize1',
        title: t('products.plant_size1'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'plantSize2',
        title: t('products.plant_size2'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'plantWidth',
        title: t('products.plant_width'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'plantHeight',
        title: t('products.plant_height'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'age',
        title: t('products.age'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
      {
        id: 'price',
        title: t('products.price'),
        type: ColumnType.Custom,
        sortable: true,
        filterable: false,
        alignment: Alignment.Right,
        width: 130,
        visible: true,
        format: plant => <span>{priceFormat((plant.price || '0').replace(',', '.'))}</span>
      },
      {
        id: 'quantity',
        title: t('products.quantity'),
        type: ColumnType.Custom,
        sortable: true,
        filterable: false,
        alignment: Alignment.Right,
        width: 130,
        visible: true,
        format: (plant) => (
            <span>{plant.quantity || '0'}</span>
        ),
      },
      {
        id: 'priceGroupId',
        title: t('products.price_group'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
      },
      {
        id: 'notes',
        title: t('products.notes'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
      },
      {
        id: 'packSize',
        title: t('products.pack_size'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
      },
      {
        id: 'packWeight',
        title: t('products.pack_weight'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: true,
      },
      {
        id: 'lastChanged',
        title: t('products.last_changed'),
        type: ColumnType.DateTime,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        width: 130,
        visible: false,
      },
      {
        id: 'notes2',
        title: t('products.notes2'),
        type: ColumnType.Text,
        sortable: true,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
      },
    ] as Column<Product>[];

    const fields = [...this.state.customFields];
    fields.sort((a, b) => a.order - b.order);
    fields.forEach(field => {
      columns.push({
        id: field.id,
        title: field.label,
        type: ColumnType.Text,
        sortable: false,
        filterable: false,
        alignment: Alignment.Left,
        flex: 1,
        visible: false,
        format: item => typeof item.extras[field.id] !== 'undefined' ? item.extras[field.id] : ''
      })
    });

    columns.push({
      id: 'edit',
      title: t('edit'),
      type: ColumnType.Text,
      sortable: false,
      filterable: false,
      alignment: Alignment.Left,
      width: 110,
      visible: !master,
      format: (product) => (
        <Button icon="edit" onClick={() => this.onEdit(product)} minimal />
      ),
    });

    return columns
  }

  private fetchCustomFields = async () => {
    const { items } = await CustomFieldRepository.index(
      0,
      1000,
      'id',
      'asc',
      {}
    );

    this.setState({ customFields: items });
  };

  private fetch = async (
    page: number,
    pageSize: number,
    sort: string,
    sortDir: string,
    filters: FilterStates
  ) => {
    let query = this.state.query.trim();
    if (this.state.thirdcategory.exists) {
      query += `&category_id=${this.state.thirdcategory.id}`;
    } else if (this.state.subcategory.exists) {
      query += `&category_id=${this.state.subcategory.id}`;
    } else if (this.state.category.exists) {
      query += `&category_id=${this.state.category.id}`;
    }

    const { items, total, cancelToken } = await ProductRepository.index(
      page,
      pageSize,
      sort,
      sortDir,
      filters,
      this.cancelToken,
      `query=${query}`
    );
    this.cancelToken = cancelToken;
    return { items, total };
  };

  private fetchMaster = async (
    page: number,
    pageSize: number,
    sort: string,
    sortDir: string,
    filters: FilterStates
  ) => {
    let query = this.state.masterQuery.trim();
    if (this.state.category.exists) {
      query += `&category_id=${this.state.category.id}`;
    }
    const { items, total, cancelToken } = await MasterProductRepository.index(
      page,
      pageSize,
      sort,
      sortDir,
      filters,
      this.cancelToken,
      `query=${query}`
    );
    this.cancelToken = cancelToken;
    return { items, total };
  };

  private onOwnSelectionChange = (ownSelection: Product[]) => {
    this.setState({ ownSelection });
  }

  private canDelete = () => {
    return this.state.ownSelection.length === 0;
  };

  private addProduct = () => {
    const product = new Product({});
    this.setState({ selected: product, showDialog: true });
  };

  private importSelected = async () => {
    const selection = this.masterTable.current?.state.selection || {};
    const ids = Object.keys(selection).filter((key) => selection[key]);
    await axios.post('/products/import_selected', ids);
    this.ownTable.current?.fetch();
  };

  private importAll = async () => {
    await axios.post('/products/import_all', {});
    this.ownTable.current?.fetch();
  };

  private deleteProducts = () => {
    this.setState({ isLoading: true }, async () => {
      const ids = this.ownTable.current?.getSelectedIds() ?? [];
      await axios.post('/products/delete_multiple', ids);
      this.ownTable.current?.fetch();
      this.setState({ isLoading: false });
    });
  };

  private onDialogClose = () => {
    this.setState({
      selected: new Product({}),
      showDialog: false
    }, () => {
      this.ownTable.current?.fetch();
    });
  };

  private onQueryChange = (query: string) => {
    if (this.cancelToken) {
      this.cancelToken();
    }
    this.setState({ query }, () => this.ownTable.current?.fetch());
  };

  private onMasterQueryChange = (masterQuery: string) => {
    if (this.cancelToken) {
      this.cancelToken();
    }
    this.setState({ masterQuery }, () => this.masterTable.current?.fetch());
  };

  private onClone = (product: Product) => {
    this.setState({ showDialog: false }, () => {
      setTimeout(() => {
        this.setState({
          selected: product,
          showDialog: true,
        });
      }, 200);
    });
  };

  private onCategoryChange = (category: Category, master?: boolean) => {
    this.setState({ category }, () => {
      if (master) {
        this.masterTable.current?.fetch()
      } else {
        this.ownTable.current?.fetch()
      }
    })
  }


  private onSubCategoryChange = (subcategory: Category, master?: boolean) => {
    this.setState({ subcategory }, () => {
      if (master) {
        this.masterTable.current?.fetch()
      } else {
        this.ownTable.current?.fetch()
      }
    })
  }

  private onThirdCategoryChange = (thirdcategory: Category, master?: boolean) => {
    this.setState({ thirdcategory }, () => {
      if (master) {
        this.masterTable.current?.fetch()
      } else {
        this.ownTable.current?.fetch()
      }
    })
  }

  private resetCategory = (isMaster: boolean) =>
    this.setState({ category: new Category(), subcategory: new Category() }, () => {
      if (isMaster) {
        this.masterTable.current?.fetch()
      } else {
        this.ownTable.current?.fetch()
      }
    });

  private resetSubCategory = (isMaster: boolean) => {
    this.setState({ subcategory: new Category() }, () => {
      if (isMaster) {
        this.masterTable.current?.fetch()
      } else {
        this.ownTable.current?.fetch()
      }
    });
  }

  private resetThirdCategory = (isMaster: boolean) => {
    this.setState({ thirdcategory: new Category() }, () => {
      if (isMaster) {
        this.masterTable.current?.fetch()
      } else {
        this.ownTable.current?.fetch()
      }
    });
  }

  private detailRenderer = (master: boolean) => (product: Product) => {
    const { t } = this.props;
    return (
      <FlexRow className="p-24 product-detail">
        <FlexRow flex={1}>
          <img
            style={{ height: 120 }}
            src={
              master
                ? `https://print.jit.fi/images/${product.remoteId}-0.jpg`
                : product.images.length > 0
                ? `https://print.jit.fi/images/${product.organization_id}/${product.id}/${product.images[0].fileName}`
                : ''
            }
            onError={(error) => {
              (error.target as any).style = 'display: none;';
            }}
            alt=""
          />

          <div className="white p-l-24 p-r-24">
            <h1 className="m-t-0 m-b-0 white">{product.nameLatin}</h1>
            <div>{product.name1}</div>
            <div>{product.name2}</div>
          </div>
        </FlexRow>
        <FlexRow flex={1} className="p-t-6 ai-e">
          <div className="white p-r-24 w-100p">
            <FlexRow flex={1}>
              <div className="f-1 label">{t('products.color')}</div>
              <div className="f-1">{product.color}</div>
            </FlexRow>

            <FlexRow>
              <div className="f-1 label">{t('products.blossom')}</div>
              <div className="f-1">{product.blossom}</div>
            </FlexRow>

            <FlexRow>
              <div className="f-1 label">{t('products.ground')}</div>
              <div className="f-1">{product.ground}</div>
            </FlexRow>

            <FlexRow>
              <div className="f-1 label">{t('products.grow_zone')}</div>
              <div className="f-1">{product.growZone}</div>
            </FlexRow>
          </div>
        </FlexRow>
        <FlexRow flex={1} className="p-t-6 ai-e">
          <div className="white p-r-24 w-100p">
            <div className="f-1 label">{t('products.notes')}</div>
            <div className="f-1">{product.notes}</div>
            {product.notes2 && product.notes2.length > 0 && (
              <div className="f-1">{product.notes2}</div>
            )}
          </div>
        </FlexRow>
      </FlexRow>
    );
  };

  private renderOwnProducts = () => {
    const { t } = this.props;
    return (
      <div className="p-l-24 p-r-24 p-b-24">
        <Toolbar className="">
          <FlexRow flex={3}>
            <Button
              text={t('products.new_product')}
              intent="primary"
              icon={<Icon icon="plus" color="white" />}
              onClick={this.addProduct}
            />
            <FlexRow className="m-l-24">
              <CategorySelect
                category={this.state.category}
                onChange={(category) => this.onCategoryChange(category, false)}
              />
              <Button
                icon="cross"
                onClick={() => this.resetCategory(false)}
                minimal
                disabled={!this.state.category.exists}
              />
              { this.state.category.exists && <><CategorySelect
                parent={this.state.category}
                category={this.state.subcategory}
                onChange={(category) => this.onSubCategoryChange(category, false)}
              />
              <Button
                icon="cross"
                onClick={() => this.resetSubCategory(false)}
                minimal
                disabled={!this.state.category.exists}
              /></> }
              { this.state.subcategory.exists && this.state.subcategory.parent && this.state.subcategory.parent.exists && <><CategorySelect
                parent={this.state.subcategory}
                category={this.state.thirdcategory}
                onChange={(category) => this.onThirdCategoryChange(category, false)}
              />
              <Button
                icon="cross"
                onClick={() => this.resetThirdCategory(false)}
                minimal
                disabled={!this.state.thirdcategory.exists}
              /></> }
            </FlexRow>
            <FormGroup inline label={t('products.search')} className="m-l-24">
              <InputGroup
                placeholder={t('products.search_placeholder')}
                style={{ color: 'white' }}
                value={this.state.query}
                onChange={(evt) => this.onQueryChange(evt.currentTarget.value)}
              />
            </FormGroup>
            <Button intent="primary" icon="search" minimal className="m-r-24" />
          </FlexRow>
          <FlexRow className="jc-e" flex={1}>
            <Button
              outlined
              disabled={this.canDelete()}
              intent="danger"
              text={t('products.delete_products')}
              icon="cloud-download"
              className="m-r-12"
              onClick={this.deleteProducts}
            />
          </FlexRow>
        </Toolbar>

        <ProductsTable
          id="products-table"
          className="products-table"
          ref={this.ownTable}
          columns={this.getColumns()}
          fetch={this.fetch}
          sort="id"
          sortDir="asc"
          onItemSelect={this.onSelect}
          expandable
          multiple
          onSelectionChange={this.onOwnSelectionChange}
          detailRenderer={this.detailRenderer(false)}
        />
      </div>
    );
  };

  private renderMasterProducts = () => {
    const { t } = this.props;
    return (
      <div className="p-l-24 p-r-24 p-b-24">
        <Toolbar className="p-l-24">
          <FlexRow className="m-l-24">
            <CategorySelect
              category={this.state.category}
              onChange={(category) => this.onCategoryChange(category, true)}
            />
            <Button
              icon="cross"
              onClick={() => this.resetCategory(true)}
              minimal
              disabled={!this.state.category.exists}
            />
          </FlexRow>
          <FormGroup inline label={t('products.search')}>
            <InputGroup
              placeholder={t('products.search_placeholder')}
              style={{ color: 'white' }}
              value={this.state.masterQuery}
              onChange={(evt) => this.onMasterQueryChange(evt.currentTarget.value)}
            />
          </FormGroup>
          <Button intent="primary" icon="search" minimal />
          <Button
            intent="primary"
            text={t('products.import_selected')}
            icon={<Icon icon="upload" color="white" />}
            className="m-l-24"
            onClick={this.importSelected}
          />
          <Button
            intent="primary"
            text={t('products.import_all')}
            icon={<Icon icon="cloud-upload" color="white" />}
            className="m-l-12"
            onClick={this.importAll}
          />
        </Toolbar>

        <ProductsTable
          id="products-table"
          ref={this.masterTable}
          columns={this.getColumns(true)}
          fetch={this.fetchMaster}
          sort="id"
          sortDir="asc"
          onItemSelect={this.onSelect}
          multiple
          showSelection
          expandable
          detailRenderer={this.detailRenderer(true)}
        />
      </div>
    );
  };

  public render() {
    const { t } = this.props;
    return (
      <MainLayout title={t('products.title')}>
        <Breadcrumbs title={t('products.title')} />

        <Tabs
          selectedTabId={this.state.tab}
          className="custom-tabs"
          onChange={this.onTabChange}>
          <Tab id="own-products" title={t('products.own_products')} />
          <Tab id="master-products" title={t('products.master_products')} />
        </Tabs>

        <div className="products-table">
          {this.state.tab === 'master-products' && this.renderMasterProducts()}
          {this.state.tab === 'own-products' && this.renderOwnProducts()}
        </div>

        <ProductDialog
          fields={this.state.customFields}
          isOpen={this.state.showDialog}
          onClose={this.onDialogClose}
          onClone={this.onClone}
          product={this.state.selected}
        />

        <Dialog
          isOpen={this.state.isLoading}
          title={t('please_wait')}
          style={{ width: 360 }}
        >
          <div className={Classes.DIALOG_BODY}>
            <FlexRow className="ai-c jc-c">
              <Spinner size={64} />
            </FlexRow>
          </div>
        </Dialog>
      </MainLayout>
    );
  }
}

export default withTranslation()(withRouter(Products));
